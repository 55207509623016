import React from "react"
import { graphql } from "gatsby"
import Moment from "react-moment"

// Layout
import Layout from "../components/layout"
// Components
import Menu from "../components/home/menu"
import Breadcrumb from "../components/elements/breadcrumb"
import ItemSingle from "../components/elements/item-single"
import CustomComponent from "../components/elements/custom-component"
// Hooks
import { useAsmetHome } from "../hooks/use-asmet-home"
import { useGroupedComponents } from "../hooks/use-grouped-components"

import { DiscussionEmbed } from "disqus-react"

const AsmetBlogArticleTemplate = ({ data }) => {
  const article = data.blogArticle
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_SHORT_NAME,
    config: { identifier: article.strapiId },
  }

  const asmetHome = useAsmetHome()
  const groupedComponents = useGroupedComponents(article.contenido)

  const crumbs = [
    { path: `/blog`, label: "Blog" },
    { path: `/blog/${article.slug}`, label: article.titulo },
  ]

  return (
    <Layout
      meta={
        article.meta
          ? { ...article.meta, og_tipo: "blog" }
          : {
              og_tipo: "blog",
              og_titulo: article.titulo,
              og_descripcion: article.descripcion || null,
              og_imagen: article.imagen_principal || null,
            }
      }
    >
      <div id="asm-template-header" className="contanier-fluid pt-3 pb-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <Breadcrumb crumbs={crumbs} />
              <h3 className="text-white-alpha text-left pt-4">{article.titulo}</h3>
            </div>
          </div>
        </div>
      </div>
      <div id="asm-page-general">
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 mb-5">
              <ItemSingle
                item={{
                  id: article.strapiId,
                  titulo: article.titulo,
                  descripcion: article.descripcion,
                  imagen: article.imagen_principal,
                }}
              />
            </div>
            <div className="col-12 col-sm-8 mb-n5">
              <p className="text-gray-beta text-right">
                <Moment format="LL">{article.publicado_en}</Moment>
              </p>
            </div>
          </div>
        </div>

        {groupedComponents.map((item, index) => {
          return <CustomComponent component={item} index={index} key={index} pageId={article.slug} />
        })}
      </div>
      <div className="container my-5">
        <DiscussionEmbed {...disqusConfig} />
      </div>
      <hr />
      <Menu menuSecundario={asmetHome.menu_secundario} />
    </Layout>
  )
}

export default AsmetBlogArticleTemplate

export const query = graphql`
  query BlogArticle($id: String!) {
    blogArticle: strapiBlogArticulo(strapiId: { eq: $id }) {
      strapiId
      slug
      titulo
      descripcion
      meta {
        og_titulo
        og_imagen {
          publicURL
        }
        og_descripcion
      }
      publicado_en
      imagen_principal {
        publicURL
      }

      contenido {
        id
        tipo_componente

        # Component Texto

        contenido

        # Component Documento

        titulo
        fecha_publicacion
        es_descargable
        descripcion
        archivo {
          publicURL
          extension
        }

        # Component Banner

        slides {
          tipo_componente
          titulo
          subtitulo
          imagen {
            publicURL
          }
          link {
            nombre
            tipo
            link_id
            icono {
              publicURL
            }
          }
        }

        # Component Card

        imagen {
          publicURL
        }
        link {
          nombre
          tipo
          link_id
          icono {
            publicURL
          }
        }

        # Component Grupo Cards

        cards {
          tipo_componente
          titulo
          descripcion
          imagen {
            publicURL
          }
          link {
            nombre
            tipo
            link_id
            icono {
              publicURL
            }
          }
        }
      }
    }
  }
`
